import React from 'react'

import LayoutOB from '../../components/layoutob.js'
import { useLocation } from '@reach/router'

import styled from "styled-components"

import FormOnboarding from '../../components/Form/onboarding/FormOnboarding'
import { deDE } from "../../lang/de-DE"

const Wrapper = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex: 1 0 auto;
min-height: 80vh;
`

export default function Onboarding() {

    const location = useLocation();
    var searchParams = new URLSearchParams(location.search);
    var countryParam = searchParams.get("country");
    let language = "de-DE";
    let txt = deDE.Onboarding;

    return (
        <LayoutOB location={countryParam} language={language}>
        <Wrapper>
            <FormOnboarding country={countryParam} txt={txt} language={language}/>
            </Wrapper>
        </LayoutOB>
    )
}